import { render, staticRenderFns } from "./TimeCurationModal.vue?vue&type=template&id=1095d35f&scoped=true"
import script from "./TimeCurationModal.vue?vue&type=script&lang=js"
export * from "./TimeCurationModal.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/common/modal.css?vue&type=style&index=0&id=1095d35f&prod&scoped=true&lang=css&external"
import style1 from "@/assets/css/curation/timecurationmodal.css?vue&type=style&index=1&id=1095d35f&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1095d35f",
  null
  
)

export default component.exports