<template>
    <div class="aplayz_modal">
        <div class="aplayz_modal_confirm_wrap timecuration_modal_wrap">
            <img class="modal_closebtn cursor" @click="$emit('click-close')" src="/media/img/close_btn.png" alt="닫기" />

            <ul class="flex_column">
                <li class="curaion_timeinfo">
                    <ul class="flex_column">
                        <li class="align_center">
                            <span class="timecuration_color" :class="curationinfo.colorcss"> </span>
                            <span class="timecuration_time_text">{{ curationinfo.title }}</span>
                        </li>
                        <li class="timecuration_day_text" v-if="curationinfo.day">
                            {{ curationinfo.day }}
                        </li>
                    </ul>
                </li>
                <li class="curation_info">
                    <ul class="curation_list flex_wrap">
                        <li class="curation_item" v-if="curationinfo.target">{{ curationinfo.target }}</li>
                        <li class="curation_item" v-if="curationinfo.targetUnit">{{ curationinfo.targetUnit }}</li>
                        <li class="curation_item" v-if="curationinfo.domesticRate">{{ curationinfo.domesticRate }}</li>
                        <li class="curation_item" v-if="curationinfo.genre">{{ curationinfo.genre }}</li>
                        <li class="curation_item" v-if="curationinfo.period">{{ curationinfo.period }}</li>
                        <li class="curation_item" v-if="curationinfo.emotion">{{ curationinfo.emotion }}</li>
                    </ul>
                </li>
                <li class="confirm_btn_wrap">
                    <button class="confirm_btn" @click="onClickModify">
                        수정하기
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'TimeCurationModal',
  emits: ['click-close', 'move-modifycuration'],
  props: {
    curationinfo: {
      curationId: '',
      spaceId: '',
      target: '',
      targetUnitList: '',
      domesticRate: '',
      genre: '',
      period: '',
      emotion: '',
      day: null,
      title: '',
      startAmPm: null,
      endAmPm: '',
      startTimeTitle: null,
      endtTimeTitle: null,
      colorcss: 'first'
    }
  },
  data () {
    return {};
  },

  mounted () {},

  methods: {
    onClickModify () {
      this.$emit('move-modifycuration', {
        spaceId: this.curationinfo.spaceId,
        curationId: this.curationinfo.curationId
      });
    }
  }
});
</script>
<style scoped src="@/assets/css/common/modal.css"></style>
<style scoped src="@/assets/css/curation/timecurationmodal.css"></style>
